const CONSTANTS = {
	REGEX: {
		APP_NAME: /appname:([^|]+)/i,
		APP_VERSION: /appversion:([0-9._]+)/,
		APP_STORE_NAME: /store:([^|]+)/i
	},
	STORE_NAMES: {
		AMAZON: 'amazon app store',
		APPLE: 'apple app store',
		GOOGLE_PLAY: 'google play store',
		SAMSUNG: 'samsung app store'
	},
	PLATFORMS: {
		MAC: 'MAC',
		WINDOWS: 'WIN'
	},
	COOKIE_NAMES: {
		LINK_FROM_APP: 'link_from_app'
	},
	URL_PARAMS: {
		LINK_FROM_APP: 'linkFromApp'
	},
	MOBILE_KEYWORDS: [
		'Mobi',
		'Android',
		'iPhone',
		'iPad',
		'iPod',
		'BlackBerry',
		'IEMobile',
		'Opera Mini'
	],
	IOS_KEYWORDS: ['iPhone', 'iPad', 'iPod']
};

/**
 * Constructs meta information about the user based on various parameters.
 * @param {object} options - The options object to construct the meta information.
 * @param {Navigator} options.navigator - The user agent string, typically from `navigator`.
 * @param {Record<string, string>} options.cookies - An object representing cookies, where keys are cookie names and values are cookie values.
 * @param {URLSearchParams} options.urlSearchParams - An instance of `URLSearchParams` for parsing the URL query parameters.
 * @returns {{[key:string]: string | boolean | null}} An object containing various flags and extracted values related to the user's environment.
 */
function userDetectionService({
	navigator,
	cookies = {},
	urlSearchParams = new URLSearchParams()
}) {
	const appNameMatches = CONSTANTS.REGEX.APP_NAME.exec(navigator.userAgent);
	const appVersionMatches = CONSTANTS.REGEX.APP_VERSION.exec(navigator.userAgent);
	const appStoreNameMatches = CONSTANTS.REGEX.APP_STORE_NAME.exec(navigator.userAgent);

	const appName = appNameMatches ? appNameMatches[1] : null;
	const appVersion = appVersionMatches ? appVersionMatches[1] : null;
	const appStoreName = appStoreNameMatches ? appStoreNameMatches[1] : null;

	const isAndroidApp = Boolean(
		appStoreName?.toLowerCase().includes(CONSTANTS.STORE_NAMES.GOOGLE_PLAY)
	);
	const isSmartTvApp = Boolean(navigator.userAgent?.toLowerCase().includes('smarttv'));

	const isAmazonApp = appStoreName?.toLowerCase() === CONSTANTS.STORE_NAMES.AMAZON;
	const isIosApp = appStoreName?.toLowerCase() === CONSTANTS.STORE_NAMES.APPLE;
	const isSamsungApp = appStoreName?.toLowerCase() === CONSTANTS.STORE_NAMES.SAMSUNG;
	const isLinkFromApp =
		cookies[CONSTANTS.COOKIE_NAMES.LINK_FROM_APP] === 'true' ||
		urlSearchParams.get(CONSTANTS.URL_PARAMS.LINK_FROM_APP) === 'true';

	const isMac = navigator.platform?.toUpperCase().includes(CONSTANTS.PLATFORMS.MAC);
	const isWindows = navigator.platform?.toUpperCase().includes(CONSTANTS.PLATFORMS.WINDOWS);

	// TODO: consider if this method is accurate over cloudfront headers
	const isDesktopViewer = isMac || isWindows;
	const isMobileViewer = CONSTANTS.MOBILE_KEYWORDS.some((keyword) =>
		navigator.userAgent.includes(keyword)
	);

	const isAmazonViewer = Boolean(navigator.userAgent?.toLowerCase().includes('amazon'));
	const isAndroidViewer = Boolean(navigator.userAgent?.toLowerCase().includes('android'));
	const isIosViewer = CONSTANTS.IOS_KEYWORDS.some((keyword) =>
		navigator.userAgent.includes(keyword)
	);

	const config = {
		inApp: Boolean(appName || appVersion || appStoreName),
		appName: appName,
		appVersion: appVersion,
		appStoreName: appStoreName,
		isAndroidApp: isAndroidApp,
		isSmartTvApp: isSmartTvApp,
		isIosApp: isIosApp,
		isSamsungApp: isSamsungApp,
		isLinkFromApp: isLinkFromApp,
		isAmazonViewer: isAmazonViewer,
		isMac: isMac,
		isWindows: isWindows,
		isMobileViewer: isMobileViewer,
		isDesktopViewer: isDesktopViewer,
		isAmazonApp: isAmazonApp,
		isAndroidViewer: isAndroidViewer,
		isIosViewer: isIosViewer
	};

	const viewerCountry = urlSearchParams.get('viewerCountry');
	if (viewerCountry) return { ...config, viewerCountry };

	return config;
}

export { userDetectionService };
